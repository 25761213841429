import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Header } from '../shared/elements'
import Form from './Form'
import Sent from './Sent'
import { checkLogin } from '../../services/auth'

export default function ResetPassword(props) {
  const [showForm, setShowForm] = useState(true)

  const afterSubmit = () => navigate('/')

  useEffect(() => {
    const authenticated = checkLogin()
    if (authenticated) {
      afterSubmit()
    }
  })

  return (
    <>
      <Header />
      {showForm ? <Form afterSubmit={() => setShowForm(false)} /> : <Sent />}
    </>
  )
}
